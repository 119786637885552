import React from "react"
import { graphql} from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Announcement from "../components/announcement"
import ImageWithText from "../components/image-with-text"
import ImageWithTextOverlay from "../components/image-with-text-overlay"

import aboutUsImage from "../assets/home/pv-restaurant-interior.jpg"

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark
  const pvBanner = "/home/pv-home-banner.jpg";
  const link = "/menu"
  const cta = "view menu"

  const ourFoodBGImage = "/home/pv-home-banner.jpg";

  return (
    <Layout title="Pancho Villa Restaurant" description="At Pancho Villa Restaurant we serve the finest Mexican cuisine using authentic Mexican herbs and recipes.">
      
      <Banner title={frontmatter.banner.title} preheading={frontmatter.banner.preheading} imageUrl={pvBanner} link={link} button={frontmatter.banner.buttonText} />

      {frontmatter.announcementBar.showAnnouncement &&
        <Announcement content={frontmatter.announcementBar.content} color={frontmatter.announcementBar.bgColor} />
      }

      <ImageWithText image={aboutUsImage} title={frontmatter.aboutUs.title} text={frontmatter.aboutUs.textContent} />

      <ImageWithTextOverlay link="/menu" text={frontmatter.ourFood.content} backgroundImage={ourFoodBGImage} title={frontmatter.ourFood.title} buttonText={frontmatter.ourFood.buttonText}/>

    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        banner {
          preheading
          title
          buttonText
        }
        announcementBar {
          showAnnouncement
          content
          bgColor
        }
        aboutUs {
          title
          textContent
        }
        ourFood {
          title
          content
          buttonText
        }
      }
    }
  }
`