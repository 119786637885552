import React from "react"
import { Link } from "gatsby"

export default function ImageWithTextOverlay(props) {
  const imageWithTextOverlay = "../menu/pv-menu-banner.jpg";
  return (
     <section className="section banner has-text-centered image-with-text-overlay" style={{
                    backgroundImage: `url(${imageWithTextOverlay})`,
                    height: "auto"
                  }}>
      <div className="container">
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", position:"relative", zIndex:"3"}}>
          <div>
            <div className="content has-text-secondary">
              <h3>{props.title}</h3>
              {props.text}
            </div>
            <div className="content">
              <Link className="button transition is-outlined" to={props.link}>{props.buttonText}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}