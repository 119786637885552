import React from "react"

import remark from 'remark'
import remarkHTML from 'remark-html'

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Announcement(props) {
  return (
    <section className={"announcement-bar has-text-centered"} style={{backgroundColor: props.color}}>
      <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(props.content)}} />
    </section>
  )
}