import React from "react"

import image from "../assets/home/pv-restaurant-interior.jpg"

export default function ImageWithText(props) {
  return (
    <section className={"section image-with-text"}>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-half">
            <img src={image} alt="The interior of Pancho Villa Restaurant"></img>
          </div>
          <div className="column is-half">
            <h3>{props.title}</h3>
            {props.text}
          </div>
        </div>
      </div>
    </section>
  )
}